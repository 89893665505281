
import { Style } from 'react-style-tag';

function Hero()
{
  return (
    <section className="elementor-section elementor-top-section elementor-element elementor-element-dfe6572 elementor-section-height-min-height first-section elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
             data-id="dfe6572" data-element_type="section"
             data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
      <div className="elementor-background-overlay">
      </div>
      <div className="elementor-container elementor-column-gap-custom">
        <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-fff4b7a"
             data-id="fff4b7a" data-element_type="column">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-fcf4fd7 elementor-widget elementor-widget-image"
                 data-id="fcf4fd7" data-element_type="widget"
                 data-settings="{&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:2.9,&quot;sizes&quot;:[]},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;],&quot;motion_fx_translateY_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:0,&quot;end&quot;:100}}}"
                 data-widget_type="image.default">
              <div className="elementor-widget-container">
                <img decoding="async" fetchpriority="high" width="1500" height="1945"
                     src="./images/test-1.png"
                     className="attachment-full size-full wp-image-15" alt=""
                     srcSet="./images/test-1.png 1500w, ./images/test-1-231x300.png 231w, ./images/test-1-790x1024.png 790w, ./images/test-1-768x996.png 768w, ./images/test-1-1185x1536.png 1185w"
                     sizes="(max-width: 1500px) 100vw, 1500px" />
              </div>
            </div>
          </div>
        </div>
        <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-af96d71"
             data-id="af96d71" data-element_type="column"
             data-settings="{&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_direction&quot;:&quot;negative&quot;,&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;],&quot;motion_fx_translateY_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:4,&quot;sizes&quot;:[]},&quot;motion_fx_translateY_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:0,&quot;end&quot;:100}}}">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-26f09e9 elementor-widget elementor-widget-heading"
                 data-id="26f09e9" data-element_type="widget" data-widget_type="heading.default">
              <div className="elementor-widget-container">
                <Style>{`
                  .elementor-heading-title {
                    padding: 0;
                    margin: 0;
                    line-height: 1
                  }

                  .elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a {
                    color: inherit;
                    font-size: inherit;
                    line-height: inherit
                  }

                  .elementor-widget-heading .elementor-heading-title.elementor-size-small {
                    font-size: 15px
                  }

                  .elementor-widget-heading .elementor-heading-title.elementor-size-medium {
                    font-size: 19px
                  }

                  .elementor-widget-heading .elementor-heading-title.elementor-size-large {
                    font-size: 29px
                  }

                  .elementor-widget-heading .elementor-heading-title.elementor-size-xl {
                    font-size: 39px
                  }

                  .elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
                    font-size: 59px
                  }
                `}</Style>
                 <h1 className="elementor-heading-title elementor-size-default">
                   Digital Rocket <span style={{ color: '#EE6321' }}>Science</span>
                 </h1>
               </div>
             </div>
             <div className="elementor-element elementor-element-e349ff7 elementor-widget elementor-widget-text-editor"
                  data-id="e349ff7" data-element_type="widget"
                  data-widget_type="text-editor.default">
               <div className="elementor-widget-container">
                 <Style>{`
                   .elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {
                     background-color: #69727d;
                     color: #fff
                   }

                   .elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {
                     color: #69727d;
                     border: 3px solid;
                     background-color: transparent
                   }

                   .elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {
                     margin-top: 8px
                   }

                   .elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {
                     width: 1em;
                     height: 1em
                   }

                   .elementor-widget-text-editor .elementor-drop-cap {
                     float: left;
                     text-align: center;
                     line-height: 1;
                     font-size: 50px
                   }

                   .elementor-widget-text-editor .elementor-drop-cap-letter {
                     display: inline-block
                   }
                 `}</Style>
                 <p>
                   Advanced numerical simulation tools for<br/> STEM education,
                   learning, and exploration.
                 </p>
               </div>
             </div>
             <div className="elementor-element elementor-element-c5e9dd1 elementor-align-right elementor-widget elementor-widget-button"
                  data-id="c5e9dd1" data-element_type="widget" data-widget_type="button.default">
               <div className="elementor-widget-container">
                 <div className="elementor-button-wrapper">
                   <a className="elementor-button elementor-button-link elementor-size-sm"
                      href="#about-us">
                     <span className="elementor-button-content-wrapper">
                       <span className="elementor-button-text">
                         Discover More
                       </span>
                     </span>
                   </a>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </section>
  );
}

export default Hero;
